<template>
  <v-container class="mt-14">
    <SidebarBulk />
    <BackComponent :title_name="$t('message.title-add-address')" />
    <div>
      <v-form @submit.prevent="submitFormAddress">
        <v-row style="margin-top: 25px">
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{ $t("message.label-address-name") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              required
              outlined
              v-model="groupAdd.group_name"
              :error-messages="group_nameErrors"
              @input="$v.groupAdd.group_name.$touch()"
              @blur="$v.groupAdd.group_name.$touch()"
            />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{ $t("message.label-postal") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-row align="center">
              <v-col cols="8">
                <v-text-field
                  v-model="groupAdd.postalCode"
                  :error-messages="postalErrors"
                  @input="$v.groupAdd.postalCode.$touch()"
                  @blur="$v.groupAdd.postalCode.$touch()"
                  required
                  outlined
                />
              </v-col>
              <v-col class="mb-5">
                <v-btn
                  depressed
                  :disabled="isPostal"
                  outlined
                  class="ml-2 title-button"
                  @click.prevent="getAddress()"
                >
                  <span style="font-size: 10px">{{
                    $t("message.search")}}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{
              $t("message.label-prefecture")
            }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              v-model="groupAdd.prefecture"
              :error-messages="prefectureErrors"
              @input="$v.groupAdd.prefecture.$touch()"
              @blur="$v.groupAdd.prefecture.$touch()"
              required
              outlined
            />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class required">{{
              $t("message.label-city")
            }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              v-model="groupAdd.city"
              :error-messages="cityErrors"
              @input="$v.groupAdd.city.$touch()"
              @blur="$v.groupAdd.city.$touch()"
              required
              outlined
            />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{ $t("message.label-district") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
            v-model="groupAdd.district"

            outlined />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{$t("message.label-address-ward")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
              outlined
              v-model="groupAdd.ward"
            ></v-text-field>
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{ $t("message.label-address-street_address") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
            v-model="groupAdd.street_address"
            outlined />
          </v-col>
          <v-col class="pb-0 pt-0" cols="12" md="2" xs="2">
            <span class="label-class">{{ $t("message.label-building") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs="2" class="pt-0 input-class">
            <v-text-field
            v-model="groupAdd.building"
            outlined />
          </v-col>
        </v-row>
        <div class="d-flex flex-row align-end justify-end">
          <v-btn
            type="submit"
            rounded
            color="#ff0090"
            style="color: white"
            @click.prevent="submitFormAddress"
          >
            {{ $t("message.btn-add") }}
          </v-btn>
        </div>
      </v-form>
      <div></div>
    </div>
  </v-container>
</template>

<script>
import SidebarBulk from "../../components/bulk/SidebarBulk.vue";
import Back from "@/components/Back.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  name: "GroupAdd",
  mixins: [validationMixin],
  validations: {
    groupAdd: {
      group_name: { required },
      postalCode: { required, maxLength: maxLength(8) },
      prefecture: { required },
      // district: { required },
      city: { required },
      // ward: { required },
      // street_address: { required },
    }
  },
  data() {
    return {
      isPostal: true,
      groupAdd: {
        group_name: "",
        postalCode: "",
        prefecture: "",
        district: "",
        city: "",
        ward: "",
        building: "",
        street_address: "",
      },
      // setSlugGroup: "",
      errMsg: "",
      titleBack: "Add Group",
    };
  },
  components: {
    BackComponent: Back,
    SidebarBulk,
  },
  computed: {
    bulkItemListGroup () {
      return this.$store.state.bulk_module.bulkItemInListGroup
    },
    group_nameErrors() {
      let errors = [];
      if (!this.$v.groupAdd.group_name.$dirty) return errors;
      !this.$v.groupAdd.group_name.required &&
        errors.push("The field group name is required!");
      return errors;
    },
    postalErrors() {
      let errors = [];
      this.isPostal = false
      if (!this.$v.groupAdd.postalCode.$dirty) return errors;
      !this.$v.groupAdd.postalCode.required &&
        errors.push(this.$t("message.err-msg-postalcode-required"));
      if (this.groupAdd.postalCode == 0) {
        errors.push(this.$t("message.err-msg-postal-zero"));
      }
      // if (this.groupAdd.postalCode.slice(0,3) != '507') {
      //   errors.push(this.$t("message.err-msg-postal-507"))
      //   }
        if (errors.length > 0) {
          this.isPostal = true
        }

      return errors;
    },
    prefectureErrors() {
      let errors = [];
      if (!this.$v.groupAdd.prefecture.$dirty) return errors;
      !this.$v.groupAdd.prefecture.required &&
        errors.push(this.$t("message.err-msg-prefecture-required"));
      return errors;
    },
    cityErrors() {
      let errors = [];
      if (!this.$v.groupAdd.city.$dirty) return errors;
      !this.$v.groupAdd.city.required &&
        errors.push(this.$t("message.err-msg-city-required"));
      return errors;
    },
    // districtErrors() {
    //   let errors = [];
    //   if (!this.$v.groupAdd.district.$dirty) return errors;
    //   !this.$v.groupAdd.district.required &&
    //     errors.push(this.$t("message.err-msg-district-required"));
    //   return errors;
    // },
    // street_addressErrors() {
    //   let errors = [];
    //   if (!this.$v.groupAdd.street_address.$dirty) return errors;
    //   !this.$v.groupAdd.street_address.required &&
    //     errors.push(this.$t("message.err-msg-street_address"));
    //   return errors;
    // },
    // wardErrors() {
    //   let errors = [];
    //   if (!this.$v.groupAdd.ward.$dirty) return errors;
    //   !this.$v.groupAdd.ward.required &&
    //     errors.push(this.$t("message.err-msg-ward"));
    //   return errors;
    // },
  },
  methods: {
    async getAddress() {
      let postCode = this.groupAdd.postalCode
      postCode = postCode.replace("-","");
      let res = await this.$store.dispatch("address_module/getAddressByPostal", {
        postal: postCode
      });
      if(res.data.length > 0) {
        let resAddress = res.data[0];
        this.groupAdd.prefecture = resAddress.pref
        this.groupAdd.city = resAddress.city
        this.groupAdd.district = resAddress.town
      } else {
        let message;
        if(typeof res.data.messsage === 'undefined')
          message = this.$t("message.err-msg-postal-code-notfound");
        else message = this.$t("message.err-msg-postal-code-api-error");

        this.groupAdd.prefecture = ""
        this.groupAdd.city = ""
        this.groupAdd.district = ""
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: message,
        });
      }
    },
    submitFormAddress() {
      this.$v.$touch();
      let refacPostcode = this.groupAdd.postalCode
      refacPostcode = refacPostcode.replace("-","")
      let payload = {
        // menu_date: this.bulkItemListGroup.menu_date,
        // store_id: this.bulkItemListGroup.store_id,
        name: this.groupAdd.group_name,
        ward: this.groupAdd.ward,
        postal: refacPostcode,
        prefecture: this.groupAdd.prefecture,
        city: this.groupAdd.city,
        district: this.groupAdd.district,
        streetAddress: this.groupAdd.street_address,
        building: this.groupAdd.building
      }
      this.$store.dispatch('address_module/addFormAddress', payload)
      let getSlug = this.$router.history.current.params.slug_group
      setTimeout(() => {
        this.$router.push(`/bulk/${getSlug}/group-list`)
      }, 800);
    },
  },
  created() {
    // this.setSlug()
  }
};
</script>

<style>
.card-class {
  border: 1px solid black !important;
}
.required:after {
  content: " *";
  color: red;
}
</style>